@import url(https://fonts.googleapis.com/css2?family=Jost&display=swap);
body,
html {
  margin: 0;
}

body {
  padding: 0;
  font-family: -apple-system, Inter, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  height: 100%;
  background-color: black; /* #ffc0c5; */
  overflow: scroll;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  background-color: black; /*#ffc0c5; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.vertical-line {
  border-left: 3px solid #f0031a;
  margin-left: 10px;
  margin-right: 10px;
  width: 5px;
  height: 40px;
}

.mint-container {
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mint-quantity {
  width: 100px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mint-options {
  display: flex;
  flex-direction: column;
  width: 100%;
}



.mint-text {
  margin-top: 0px;
  margin-bottom: 5px;
}

.content {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.sample-img {
  width: 90%;
}

.mint-options {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mint-general {
  display: flex;
  width: 100%;
}

.mint-specific {
  display: flex;
  width: 100%;
  max-width: 450px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.header {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
}

.sub-text {
  font-size: 25px;
  color: white;
  text-align: center;
  padding: 0px;
  margin: 0px;
}

.smol-container {
  margin-top: 5%
}

.smol {
  font-size: 18px;
  font-weight: italic;
}

.title {
  margin-bottom: 10px;
}

.gradient-text {
  text-align: center;
  background: -webkit-linear-gradient(80deg, #f5707e 0%, #f0031a 50%, #3e0100 100%);
  font-family: 'Jost', sans-serif!important;
  font-size: 125px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

a {
  color: blue;
  font-weight: bold;
  text-decoration: none; /* no underline */
}

.banner {
  background-size: 100% auto;
  background-position: center 60%;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  border-color: #f0031a;
  border-style: solid;
  border-width: 0px 0px 2px 0px;
}

.banner-logo {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  margin-top: 2%;
  width: 25%;
}

.logo-img {
  width: 100%;
}

.title-text {
  font-family: 'Jost', sans-serif!important;
  color: white;
  font-size: 18px;
  padding: 0;
  margin: 10px;
  font-weight: bold;
}

.countdown {
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin: 5px;
  padding: 0;
  font-family: 'Jost', sans-serif!important;
}

.wl-text {
  color: greenyellow;
  font-weight: bold;
  /* font-size: 30px; */
}

.no-wl-text {
  color: orangered;
  font-weight: bold;
  /* font-size: 30px; */
}

.countdown-large {
  color: white;
  font-weight: bold;
  font-size: 30px;
  margin: 0;
  padding: 0;
  font-family: 'Jost', sans-serif!important;
}

.teaser {
  margin-top: 20px;
  width: 70%;
}

@media (max-width: 1000px) {
  .teaser {
    width: 90%;
  }

  .logo {
    margin-top: 5%;
    width: 40%;
  }

}

.supply {
  font-size: 15px;
  color: white;
}

p.supply a {
  color: white;
}

.textarea-entry {
  margin-left: 5%;
  height: 50px;
  width: 75px;
  border-radius: 10px;
  border: 1px solid #000;
  font-size: 15px;
}

.social-icon-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

.discord-logo {
  width: 50px;
  margin-top: 10px;
  height: auto;
  /* margin-right: 20px; */
}

.social-icon-twitter {
  width: 50px;
  height: auto;
}

.cta-button {
  height: auto;
  border: 0;
  width: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}

.textarea-entry {
  text-align: center;
  font-size: 20px;
  border-radius: 2px;
  color: white;
  font-weight: bold;
  background-color: black;
  border: 3px solid white; 
  font-family: 'Jost', sans-serif!important;
}

.connect-wallet-button {
  font-family: 'Jost', sans-serif!important;
  font-weight: bold;
  font-size: 25px;
  /* background: -webkit-linear-gradient(left, #02c5f7, #6a5cee); */
  background: -webkit-linear-gradient(80deg, #f0031a 0%, #6c0007 50%, #3e0100 100%);
  background-size: 200% 200%;
  -webkit-animation: gradient-animation 4s ease infinite;
          animation: gradient-animation 4s ease infinite;
}

.mint-button {
  /* background: -webkit-linear-gradient(left, #02c5f7, #6a5cee); */
  background: -webkit-linear-gradient(80deg, white 0%, rgb(251,24,254) 50%, rgb(253,116,139) 100%);
  background-size: 200% 200%;
  -webkit-animation: gradient-animation 4s ease infinite;
          animation: gradient-animation 4s ease infinite;
  margin-right: 15px;
}

.opensea-button {
  background-color: rgb(32, 129, 226);
}

.mint-count {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.twitter-logo {
  width: 35px;
  height: 35px;
}

.footer-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.sub-text {
  font-size: 35px;
  color: #f0031a;
  text-align: left;
  padding: 0px;
  margin: 0px;
  text-align: center;
  margin-bottom: 20px;
  max-width: 60%;
  font-family: 'Jost', sans-serif!important;
}

div.gradient-text.art-title {
  font-size: 75px;
  font-weight: bold;
  align-self: center;
}

.art-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.carousel {
  display: flex;
  width: 75%;
  margin-top: 2%;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}

.art-preview-container {
  width: 45%;
  display: flex;
}

@media (max-width: 1000px) {
  .carousel {
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }

  .art-preview-container {
    width: 85%;
  }
}

.art-text-container {
  margin-top: 20px;
  width: 50%;
  margin-left: 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.art-text-small {
  width: 100%;
  text-align: center;
  color: white;
  font-weight: normal;
  font-family: 'Jost', sans-serif!important;
  font-size: 21px;
  padding: 0;
  margin: 0;
}

.art-text-large {
  width: 100%;
  text-align: center;
  color: white;
  font-weight: bold;
  font-family: 'Jost', sans-serif!important;
  font-size: 30px;
  padding: 0;
  margin: 0;
}

@media (max-width: 1000px) {
  .art-text-container {
    width: 75%;
    margin-left: 2.5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .art-text-small {
    width: 100%;
    text-align: center;
    color: white;
    font-weight: normal;
    font-family: 'Jost', sans-serif!important;
    font-size: 18px;
    padding: 0;
    margin: 0;
  }

  .art-text-large {
    width: 100%;
    text-align: center;
    color: white;
    font-weight: bold;
    font-family: 'Jost', sans-serif!important;
    font-size: 25px;
    padding: 0;
    margin: 0;
  }
}

.hidden-previews {
  margin-top: 10%;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hidden-preview-row {
  margin-bottom: 4%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.hidden-preview-img {
  width: 30%;
}

@media (max-width: 1000px) {
  .hidden-previews {
    margin-top: 10%;
    width: 85%;
  }

  .hidden-preview-img-last {
    display: none!important;
  }

  .hidden-preview-img {
    width: 47.5%;
  }
}

.hidden-preview-img-last {
  display: flex;
}

.roadmap-container {
  background-size: 200% auto;
  background-position: center center;
  background-repeat: no-repeat;
}

.roadmap-text-container {
  margin-top: 25%;
  margin-bottom: 25%;
  width: 60%;
}

.roadmap-text-small {
  width: 100%;
  text-align: left;
  color: white;
  font-weight: normal;
  font-family: 'Jost', sans-serif!important;
  font-size: 25px;
  padding: 0;
  margin: 0;
}

.roadmap-text-large {
  width: 100%;
  text-align: left;
  color: white;
  font-weight: bold;
  font-family: 'Jost', sans-serif!important;
  font-size: 100px;
  padding: 0;
  margin: 0;
}

.roadmap-divider {
  border-bottom: 2px solid white;
  width: 20%;
  margin-bottom: 5%;
  margin-top: 5%;
}

.roadmap-divider-first {
  width: 100%;
}

.class-session-container {
  width: 50%;
}

.discord-large {
  width: 50%;
}

@media (max-width: 1000px) {
  .roadmap-text-container {
    margin-top: 40%;
    margin-bottom: 40%;
    width: 65%
  }
  .roadmap-text-small {
    font-size: 20px;
  }
  .roadmap-text-large {
    font-size: 50px;
  }
  .discord-large {
    width: 70%;
  }
}

.team-container {
  margin-top: 15%;
  width: 80%;
}

.team-title {
  text-align: center;
  color: white;
  font-weight: bold;
  font-family: 'Jost', sans-serif!important;
  font-size: 75px;
  padding: 0;
  margin: 20px;
}

.team-bios {
  margin-top: 5%;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.team-bio {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}

.team-bio-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-bio-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-description-text-right {
  text-align: right;
  color: white;
  font-weight: bold;
  font-family: 'Jost', sans-serif!important;
  font-size: 25px;
  padding: 0;
  padding-left: 15px;
  margin: 5px;
}

.team-bio-img {
  height: 400px;
}

.team-bio-text {
  text-align: center;
  color: white;
  font-weight: bold;
  font-family: 'Jost', sans-serif!important;
  font-size: 30px;
  padding: 0;
  margin: 5px;
}

.team-bio-text-small {
  text-align: center;
  color: white;
  font-weight: normal;
  font-family: 'Jost', sans-serif!important;
  font-size: 20px;
  padding: 0;
  margin: 5px;
}

@media (max-width: 1000px) {
  .team-title {
    font-size: 35px;
  }

  .team-bios {
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
  }

  .team-bio {
    width: 100%;
    flex-wrap: wrap;
  }

  .team-bio-text {
    font-size: 30px;
  }
  .team-bio-text-small {
    font-size: 18px;
    font-weight: bold;
  }

  .team-bio-right {
    width: 100%;
    margin-bottom: 10%;
  }

  .team-bio-left {
    width: 100%;
  }

  .team-description-text-right {
    text-align: center;
    font-size: 19px;
    font-weight: normal;
    width: 100%;
    max-width: 500px;
  }


}

.footer-container {
  margin-top: 10%;
  width: 100%;
  padding: 0;
}

.footer {
  height: 300px;
}

@media (max-width: 1000px) {
  .footer-container {
    margin-top: 20%;
  }

  .footer {
    height: 200px;
  }
}

.vs_main_frame {
  width: 600px;
  max-width: 100%;
  height: 1071px;
  padding-top: 15%;
}

.mint-container {
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.whitelist-comp {
  color:white;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  font-family: 'Jost', sans-serif!important;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* @font-face {font-family: "Futura"; 
  src: url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.eot"); 
  src: url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.eot?#iefix") 
  format("embedded-opentype"), url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.woff2") 
  format("woff2"), url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.woff") 
  format("woff"), url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.ttf") 
  format("truetype"), url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.svg#Futura PT Medium") 
  format("svg");
} */

/* @font-face {
  font-family: "PixelarRegularW01-Regular";
  src: url("https://db.onlinewebfonts.com/t/b2e75c336df191233b3096682c9ee6be.eot");
  src: url("https://db.onlinewebfonts.com/t/b2e75c336df191233b3096682c9ee6be.eot?#iefix") format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/b2e75c336df191233b3096682c9ee6be.woff2") format("woff2"),
  url("https://db.onlinewebfonts.com/t/b2e75c336df191233b3096682c9ee6be.woff") format("woff"),
  url("https://db.onlinewebfonts.com/t/b2e75c336df191233b3096682c9ee6be.ttf") format("truetype"),
  url("https://db.onlinewebfonts.com/t/b2e75c336df191233b3096682c9ee6be.svg#PixelarRegularW01-Regular") format("svg");
} */

/* Menu Bar */
.menu {
    background-color: #1a1c1d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 75px;
    position: fixed;
    z-index: 5;
    top: 0;
    border-color: #f0031a;
    border-style: solid;
    border-width: 0px 0px 2px 0px;
  }

  .socials {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
  }

  .social-icon {
    width: 30px;
    margin-right: 40px;
  }
  
  .menu-logo {
    min-width: 20%;
    width: 30%;
    margin-left: 15%;
    display: flex;
  }
  
  .menu-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    padding-left: 10%;
  }
  
  .menu-right {
    width: 20%;
    display: flex;
    margin-right: 2%;
    justify-content: center;
    align-items: center;
  }
  
  .menu-center-container {
    text-align: center;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: flex;
    width: 100%;
  }
  
  .menu-link-div {
    display: flex;
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
    width: 20%
  }
  
  .menu-link {
    font-family: 'Jost', sans-serif!important;
    margin: 10px;
    text-decoration: none;
    font-size: 30px;
    font-weight: bold;
    color: #f0031a; /* #f0031a; */
  }

  @media (max-width: 1000px) {
    .menu-link {
      margin: 10px 0px 10px 0px;
      font-size: 20px;
    }

    /* .menu-center {
      width: 40%;
    } */

    .menu-link-div {
      margin-left: 20px;
      margin-right: 0px;
    }

    .socials {
      width: 40%;
      justify-content: flex-end;
    }

    .social-icon {
      width: 25px;
      margin-right: 12px;
    }

    .menu-right {
      display: none!important;
    }

  }


  .top-menu-connect-button {
    height: 45px;
    border: 0;
    width: 170px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
  
  .top-menu-button-disabled {
    height: 50px;
    border: 0;
    width: 170px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    cursor: default;
    font-size: 16px;
    font-weight: bold;
    color: white;
  }

  .button-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
/* @import "react-slideshow-image/dist/styles.css"; */

.react-slideshow-container {
    width: 100%;
}

.default-nav {
    display: none;
}

.nav {
    display: none!important;
}

.slide-container {
    width: 100%;
}

.slide-img {
    /* height: 350px; */
    height: 500px;
}

.each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    height: 400px;
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
