@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');

/* @font-face {font-family: "Futura"; 
  src: url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.eot"); 
  src: url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.eot?#iefix") 
  format("embedded-opentype"), url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.woff2") 
  format("woff2"), url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.woff") 
  format("woff"), url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.ttf") 
  format("truetype"), url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.svg#Futura PT Medium") 
  format("svg");
} */

/* @font-face {
  font-family: "PixelarRegularW01-Regular";
  src: url("https://db.onlinewebfonts.com/t/b2e75c336df191233b3096682c9ee6be.eot");
  src: url("https://db.onlinewebfonts.com/t/b2e75c336df191233b3096682c9ee6be.eot?#iefix") format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/b2e75c336df191233b3096682c9ee6be.woff2") format("woff2"),
  url("https://db.onlinewebfonts.com/t/b2e75c336df191233b3096682c9ee6be.woff") format("woff"),
  url("https://db.onlinewebfonts.com/t/b2e75c336df191233b3096682c9ee6be.ttf") format("truetype"),
  url("https://db.onlinewebfonts.com/t/b2e75c336df191233b3096682c9ee6be.svg#PixelarRegularW01-Regular") format("svg");
} */

/* Menu Bar */
.menu {
    background-color: #1a1c1d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 75px;
    position: fixed;
    z-index: 5;
    top: 0;
    border-color: #f0031a;
    border-style: solid;
    border-width: 0px 0px 2px 0px;
  }

  .socials {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
  }

  .social-icon {
    width: 30px;
    margin-right: 40px;
  }
  
  .menu-logo {
    min-width: 20%;
    width: 30%;
    margin-left: 15%;
    display: flex;
  }
  
  .menu-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    padding-left: 10%;
  }
  
  .menu-right {
    width: 20%;
    display: flex;
    margin-right: 2%;
    justify-content: center;
    align-items: center;
  }
  
  .menu-center-container {
    text-align: center;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: flex;
    width: 100%;
  }
  
  .menu-link-div {
    display: flex;
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
    width: 20%
  }
  
  .menu-link {
    font-family: 'Jost', sans-serif!important;
    margin: 10px;
    text-decoration: none;
    font-size: 30px;
    font-weight: bold;
    color: #f0031a; /* #f0031a; */
  }

  @media (max-width: 1000px) {
    .menu-link {
      margin: 10px 0px 10px 0px;
      font-size: 20px;
    }

    /* .menu-center {
      width: 40%;
    } */

    .menu-link-div {
      margin-left: 20px;
      margin-right: 0px;
    }

    .socials {
      width: 40%;
      justify-content: flex-end;
    }

    .social-icon {
      width: 25px;
      margin-right: 12px;
    }

    .menu-right {
      display: none!important;
    }

  }


  .top-menu-connect-button {
    height: 45px;
    border: 0;
    width: 170px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
  
  .top-menu-button-disabled {
    height: 50px;
    border: 0;
    width: 170px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    cursor: default;
    font-size: 16px;
    font-weight: bold;
    color: white;
  }

  .button-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }