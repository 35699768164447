/* @import "react-slideshow-image/dist/styles.css"; */

.react-slideshow-container {
    width: 100%;
}

.default-nav {
    display: none;
}

.nav {
    display: none!important;
}

.slide-container {
    width: 100%;
}

.slide-img {
    /* height: 350px; */
    height: 500px;
}

.each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    height: 400px;
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }